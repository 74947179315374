import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Button, Badge } from 'react-bootstrap'
import { NoteEditModal } from '../../company/components/NoteEditModal'

interface Note {
  ID: number
  created_at: string
  last_updated: string
  title: string
  status: string
  note: string
}

const CompanyNotes = ({ companyId }: { companyId: number }) => {
  const [notes, setNotes] = useState<Note[]>([])
  const [itemIdForUpdate, setItemIdForUpdate] = useState('')

  const getNotes = () => {
    axios.get(`/company/notes/getNotes/${companyId}`).then((response) => {
      if (response.data.data) {
        setNotes(response.data.data)
      }
    })
  }

  const handleView = (id: number) => {
    setItemIdForUpdate(id.toString())
  }

  const handleAdd = () => {
    setItemIdForUpdate('add')
  }

  useEffect(() => {
    getNotes()
  }, [companyId])

  const getStatusBadge = (status: string) => {
    switch (status) {
      case 'active':
        return <Badge bg='success'>Active</Badge>
      case 'passive':
        return <Badge bg='secondary'>Passive</Badge>
      default:
        return <Badge bg='dark'>{status}</Badge>
    }
  }

  const handleRefreshNotes = () => {
    getNotes()
    setItemIdForUpdate('')
  }

  return (
    <div className='card'>
      <div className='card-header border-0 pt-5 d-flex justify-content-end'>
        <div className='card-toolbar'>
          <Button variant='primary' onClick={() => handleAdd()}>
            Add Note
          </Button>
        </div>
      </div>
      <div className='card-body py-3'>
        {notes.length > 0 ? (
          <div className='table-responsive'>
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
              <thead>
                <tr className='fw-bold text-muted'>
                  <th>Actions</th>
                  <th>Title</th>
                  <th>Note</th>
                  <th>Status</th>
                  <th>Created At</th>
                  <th>Last Updated</th>
                </tr>
              </thead>
              <tbody>
                {notes.map((note) => (
                  <tr key={note.ID}>
                    <td>
                      <Button
                        variant='light-primary'
                        className='btn-sm'
                        onClick={() => handleView(note.ID)}
                      >
                        View
                      </Button>
                    </td>
                    <td>{note.title}</td>
                    <td>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: note.note
                            ? note.note.substring(0, 200) + (note.note.length > 200 ? '...' : '')
                            : '',
                        }}
                      />
                    </td>
                    <td>{getStatusBadge(note.status)}</td>
                    <td>{note.created_at}</td>
                    <td>{note.last_updated}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className='text-center py-10'>
            <h3 className='text-muted'>No notes available.</h3>
          </div>
        )}
      </div>

      {itemIdForUpdate !== '' && (
        <NoteEditModal show={true} itemIdForUpdate={itemIdForUpdate} onHide={handleRefreshNotes} />
      )}
    </div>
  )
}

export default CompanyNotes
