import { ReactNode, SyntheticEvent } from 'react'
import { FormGroup, Modal } from 'react-bootstrap'
import FormHandler from '../../utils/Form/FormHandler'
import Field from '../../utils/Form/Widgets/Field'
import AutocompleteSelect from '../../utils/Form/Widgets/AutocompleteSelect'
import Select from '../../utils/Form/Widgets/Select'
import Check from '../../utils/Form/Widgets/Check'
import axios, { AxiosResponse } from 'axios'
import Swal from 'sweetalert2'

type Props = {
  onHide: () => void
  show: boolean
  itemIdForUpdate?: string
  refreshFunction?: Function
}

class Task {
  company: number | null = null
  responsible: number | null = null
  subject: string = ''
  action: string = ''
  category: string = ''
  status: string = ''
  due_date: string = ''
}

class TaskEditModal extends FormHandler {
  statusOptions = [
    { value: 'in_progress', label: 'In Progress' },
    { value: 'pending', label: 'Pending' },
    { value: 'completed', label: 'Completed' },
    { value: 'closed', label: 'Closed' },
    { value: 'outstanding', label: 'Outstanding' },
  ]
  subjectOptions = [
    { value: 'meter_upgrade', label: 'Meter Upgrade' },
    { value: 'behavourial_changes', label: 'Behavourial Changes' },
    { value: 'software', label: 'Software' },
    { value: 'procurement', label: 'Procurement' },
    { value: 'lighting_upgrade', label: 'Lighting Upgrade' },
    { value: 'lighting_controls', label: 'Lighting Controls' },
  ]

  categoryOptions = [
    { value: 'esos', label: 'ESOS' },
    { value: 'action_plan', label: 'Action Plan' },
    { value: 'procurement', label: 'Procurement' },
    { value: 'secr', label: 'SECR' },
  ]

  constructor(props: Props) {
    super(props)
    this.state = { entity: new Task() }
  }

  getApiUrl(): string {
    return '/tasks/save/' + this.props.itemIdForUpdate
  }
  getSingleUrl(): string | null {
    if (this.props.itemIdForUpdate === 'add') {
      return null
    } else {
      return '/tasks/single/' + this.props.itemIdForUpdate
    }
  }

  getPostData(): object {
    return {
      tasks: this.state.entity,
    }
  }

  render(): ReactNode {
    var state = this.state as any
    let title = 'Add Task'
    if (this.state.entity && this.props.itemIdForUpdate !== 'add') {
      let entity = this.state.entity as any
      title = entity.name
    }
    let defaultCompanyValue = state.entity.company || 0
    if (this.props.itemIdForUpdate === 'add') {
      defaultCompanyValue = 0
    } else {
      defaultCompanyValue = state.entity.company
    }
    return (
      <Modal
        id='kt_modal_create_company'
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered'
        size='lg'
        show={this.props.show}
        backdrop={true}
        onHide={this.props.onHide}
      >
        <form
          onSubmit={async (event) => {
            event.preventDefault()
            try {
              const response = await this.handleSubmit(event)
              if (this.props.refreshFunction) {
                this.props.refreshFunction(response.data.data.entity_id)
              }
              this.props.onHide()
            } catch (error) {
              console.error('Error saving task:', error)
              Swal.fire({
                title: 'Error',
                text: 'An error occurred while saving the task. Please try again.',
                icon: 'error',
                confirmButtonText: 'OK',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              })
            }
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <AutocompleteSelect
                title='Company'
                name='company'
                onChange={(event: any) => {
                  this.handleInputChange(event)
                }}
                defaultValue={defaultCompanyValue}
                autoCompleteTokenUrl='/company/autocomplete'
              />
            </FormGroup>
            <FormGroup>
              <AutocompleteSelect
                title='Responsible'
                name='responsible'
                onChange={(event: any) => {
                  this.handleInputChange(event)
                }}
                defaultValue={state.entity.responsible}
                autoCompleteTokenUrl='/users/autocomplete'
              />
            </FormGroup>
            <FormGroup>
              <Select
                title='Subject'
                name='subject'
                options={this.subjectOptions}
                onChange={(event: any) => {
                  this.handleInputChange(event)
                }}
                defaultValue={state.entity.subject}
                required={true}
              />
            </FormGroup>
            <FormGroup>
              <Field
                title='Action'
                name='action'
                defaultValue={state.entity.action}
                onChange={(event: any) => {
                  this.handleInputChange(event)
                }}
                className='form-control'
                required={true}
              />
            </FormGroup>
            <FormGroup>
              <Select
                title='Category'
                name='category'
                options={this.categoryOptions}
                onChange={(event: any) => {
                  this.handleInputChange(event)
                }}
                defaultValue={state.entity.category}
                required={true}
              />
            </FormGroup>
            <FormGroup>
              <Field
                title='Due Date'
                name='due_date'
                type='date'
                defaultValue={state.entity.due_date}
                onChange={(event: any) => {
                  this.handleInputChange(event)
                }}
                className='form-control'
                required={true}
              />
            </FormGroup>
            <FormGroup>
              <Select
                title='Status'
                name='status'
                options={this.statusOptions}
                onChange={(event: any) => {
                  this.handleInputChange(event)
                }}
                defaultValue={state.entity.status}
                required={true}
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer className='justify-content-end'>
            {this.props.itemIdForUpdate !== 'add' ? (
              <button
                type='button'
                onClick={(event) => {
                  this.handleDelete(event).then((response) => {
                    if (this.props.refreshFunction) {
                      this.props.refreshFunction('')
                    }
                  })
                }}
                className='btn btn-danger btn-sm'
              >
                <span className='fa fa-trash'></span>
                Delete
              </button>
            ) : (
              <></>
            )}
            <button type='submit' className='btn btn-success btn-sm'>
              Save
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export { TaskEditModal }
