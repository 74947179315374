import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'
import { User } from '../../../../../app/modules/auth/core/_models'
import { meApi } from '../../../../../app/modules/auth/core/_requests'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const [user, setUser] = useState<User>()
  const [token, setToken] = useState<string | undefined>(() => {
    return localStorage.getItem('@auth/token') ?? undefined
  })

  useEffect(() => {
    const fetchUser = async () => {
      const user = await meApi(token)

      if (!user) {
        setToken(undefined)
        return
      }
      setUser(user.data)
    }
    fetchUser()
  }, [])
  let hasValidRole = false
  user?.roles.map((role) => {
    if (role == '1' || role == '2') {
      hasValidRole = true
    } else {
      hasValidRole = false
    }
  })
  if (!hasValidRole) {
    return (
      <>
        <SidebarMenuItem
          to='/dashboard'
          icon='/media/icons/duotune/art/art002.svg'
          title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
          fontIcon='bi-app-indicator'
        />
        <SidebarMenuItem
          to='/alarms/list'
          icon='/media/icons/duotune/general/gen007.svg'
          title={intl.formatMessage({ id: 'MENU.ALARMS' })}
          fontIcon='bi-app-indicator'
        />
        <SidebarMenuItemWithSub
          to='#'
          title={intl.formatMessage({ id: 'MENU.REPORTS' })}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/files/fil025.svg'
        >
          <SidebarMenuItem
            to='/reports/calendar'
            title={intl.formatMessage({ id: 'MENU.REPORTS.CALENDARVIEW' })}
            hasBullet={true}
          />{' '}
          <SidebarMenuItemWithSub
            to='#'
            title={intl.formatMessage({ id: 'MENU.ANALYTICS' })}
            fontIcon='bi-archive'
            icon='/media/icons/duotune/abstract/abs001.svg'
          >
            <SidebarMenuItem
              to='/reports/analytics-charts'
              title={intl.formatMessage({ id: 'REPORTS.ANALYTICSCHART' })}
              hasBullet={true}
            />
          </SidebarMenuItemWithSub>
        </SidebarMenuItemWithSub>
      </>
    )
  }
  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/alarms'
        icon='/media/icons/duotune/general/gen007.svg'
        title={intl.formatMessage({ id: 'MENU.ALARMS' })}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItemWithSub
        to='#'
        title={intl.formatMessage({ id: 'MENU.DATA' })}
        fontIcon='bi-app-indicator'
        icon='/media/icons/duotune/abstract/abs014.svg'
      >
        <SidebarMenuItem
          to='/data_files/list'
          title={intl.formatMessage({ id: 'MENU.DATAFILES' })}
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/data_view/list'
          title={intl.formatMessage({ id: 'MENU.DATAVIEW' })}
          hasBullet={true}
        />
        <SidebarMenuItemWithSub
          to='#'
          title={intl.formatMessage({ id: 'MENU.DATAREQUESTS' })}
          hasBullet={true}
        >
          <SidebarMenuItem
            to='/data_requests/list'
            title={intl.formatMessage({ id: 'MENU.DATAREQUESTS' })}
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/data_requests/mailbox'
            title={intl.formatMessage({ id: 'MENU.DATACOLLECTIONS.MAILBOX' })}
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub>
      {/* 
      <SidebarMenuItemWithSub
        to='#'
        title={intl.formatMessage({ id: 'MENU.REPORTS' })}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/files/fil025.svg'
      >
        <SidebarMenuItem
          to='/reports/templates'
          title={intl.formatMessage({ id: 'REPORTS.TEMPLATES' })}
          icon='/media/icons/duotune/general/gen022.svg'
        />
        <SidebarMenuItem
          to='/reports/template-reports'
          title={intl.formatMessage({ id: 'REPORTS.REPORTS' })}
          icon='/media/icons/duotune/general/gen019.svg'
        />
        <SidebarMenuItem
          to='/documents'
          title={intl.formatMessage({ id: 'REPORTS.DOCUMENTS' })}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen005.svg'
        />
      </SidebarMenuItemWithSub>
      */}
      <SidebarMenuItemWithSub
        to='#'
        title='Benchmark'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/files/fil025.svg'
      >
        <SidebarMenuItem to='/benchmark/list' title='Dashboard' hasBullet={true} />
        <SidebarMenuItem to='/benchmark/records' title='Views' hasBullet={true} />
        <SidebarMenuItem to='/benchmark/charts' title='Charts' hasBullet={true} />
        <SidebarMenuItem to='/benchmark/heatmap' title='Heatmap' hasBullet={true} />
        {/*<SidebarMenuItem to='/benchmark/performance' title='Performance' hasBullet={true} />*/}
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='#'
        title={intl.formatMessage({ id: 'MENU.SECRREPORTS' })}
        fontIcon='bi-app-indicator'
        icon='/media/icons/duotune/general/gen029.svg'
      >
        <SidebarMenuItem
          to='/secr_reports/secr_dashboard'
          title='SECR Dashboard'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/secr_reports/list'
          title={intl.formatMessage({ id: 'MENU.SECRREPORTS' })}
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/secr_documents/list'
          title={intl.formatMessage({ id: 'MENU.SECRDOCUMENTS' })}
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItem
        to='/users/list'
        title={intl.formatMessage({ id: 'MENU.USERS' })}
        fontIcon='bi-user'
        icon='/media/icons/duotune/general/gen067.svg'
      />
      <SidebarMenuItemWithSub
        to='#'
        title={intl.formatMessage({ id: 'MENU.COMPANY' })}
        fontIcon='far fa-building'
        icon='/media/icons/duotune/finance/fin006.svg'
      >
        <SidebarMenuItem
          to='/company/list'
          title={intl.formatMessage({ id: 'MENU.COMPANYLIST' })}
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/company/sites'
          title={intl.formatMessage({ id: 'MENU.COMPANYSITES' })}
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/company/meters'
          title={intl.formatMessage({ id: 'MENU.COMPANYMETERS' })}
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/company/notes'
          title={intl.formatMessage({ id: 'MENU.COMPANY.NOTES' })}
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='#'
        title={intl.formatMessage({ id: 'MENU.ANALYTICS' })}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/abstract/abs001.svg'
      >
        <SidebarMenuItem
          to='/reports/calendar'
          title={intl.formatMessage({ id: 'MENU.REPORTS.CALENDARVIEW' })}
          icon='/media/icons/duotune/general/gen014.svg'
        />

        <SidebarMenuItem
          to='/reports/analytics-charts'
          title={intl.formatMessage({ id: 'REPORTS.ANALYTICSCHART' })}
          icon='/media/icons/duotune/abstract/abs046.svg'
        />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='#'
        title={intl.formatMessage({ id: 'MENU.SETTINGS' })}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/coding/cod001.svg'
      >
        <SidebarMenuItem
          to='/settings/sftp-config'
          title={intl.formatMessage({ id: 'MENU.SETTINGSSFTPCONFIG' })}
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/settings/data-directory'
          title={intl.formatMessage({ id: 'MENU.SETTINGSDATADIRECTORY' })}
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/settings/general-parameters'
          title={intl.formatMessage({ id: 'MENU.SETTINGSGENERALPARAMETERS' })}
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/settings/conversion-factors'
          title={intl.formatMessage({ id: 'MENU.SETTINGSCONVERSIONFACTORS' })}
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/settings/energy-types'
          title={intl.formatMessage({ id: 'MENU.SETTINGSENERGYTYPES' })}
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/settings/energy-subtypes'
          title={intl.formatMessage({ id: 'MENU.SETTINGSENERGYSUBTYPES' })}
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/settings/site-features'
          title={intl.formatMessage({ id: 'MENU.SETTINGS.SITEFEATURES' })}
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='#'
        icon='/media/icons/duotune/abstract/abs025.svg'
        title='LORA-Data Collections'
        fontIcon='bi-app-indicator'
      >
        <SidebarMenuItem
          to='/lora/dashboard'
          icon='/media/icons/duotune/abstract/abs026.svg'
          title='LORA Dashboard'
          fontIcon='bi-app-indicator'
        />
        <SidebarMenuItem
          to='/lora/logs'
          icon='/media/icons/duotune/abstract/abs027.svg'
          title='LORA Logs'
          fontIcon='bi-app-indicator'
        />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem
        to='/company_tasks'
        icon='/media/icons/duotune/general/gen068.svg'
        title='Task Management'
        fontIcon='bi-app-indicator'
      />
    </>
  )
}

export { SidebarMenuMain }
