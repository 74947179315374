import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Button, Badge } from 'react-bootstrap'
import { NoteEditModal } from '../../company/components/NoteEditModal'
import { TaskEditModal } from '../../company_tasks/TaskEditModal'
import Swal from 'sweetalert2'

interface Task {
  ID: number
  company: number
  subject:
    | 'meter_upgrade'
    | 'behavourial_changes'
    | 'software'
    | 'procurement'
    | 'lighting_upgrade'
    | 'lighting_controls'
  action: string
  status: 'in_progress' | 'pending' | 'completed' | 'closed' | 'outstanding'
  due_date: string
  responsible: number
  category: 'esos' | 'action_plan' | 'procurement' | 'secr'
  created_at: string
  last_updated: string
}

const CompanyTasks = ({ companyId }: { companyId: number }) => {
  const [tasks, setTasks] = useState<Task[]>([])
  const [itemIdForUpdate, setItemIdForUpdate] = useState('')

  const getTasks = () => {
    axios.get(`/tasks/getTasksByCompany/${companyId}`).then((response) => {
      if (response.data.data) {
        setTasks(response.data.data)
      }
    })
  }

  const handleView = (id: number) => {
    setItemIdForUpdate(id.toString())
  }

  const handleAdd = () => {
    setItemIdForUpdate('add')
  }

  useEffect(() => {
    getTasks()
  }, [companyId])

  const getStatusBadge = (status: string) => {
    switch (status) {
      case 'active':
        return <Badge bg='success'>Active</Badge>
      case 'passive':
        return <Badge bg='secondary'>Passive</Badge>
      default:
        return <Badge bg='dark'>{status}</Badge>
    }
  }

  const handleRefreshNotes = () => {
    getTasks()
    setItemIdForUpdate('')
  }

  const handleDelete = async (ID: number): Promise<void> => {
    const result = await Swal.fire({
      title: 'Warning',
      html: 'Are you sure you want to delete?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel',
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-primary',
      },
    })

    if (result.isConfirmed) {
      try {
        await axios.delete(`/tasks/save/${ID}`)
        setTasks((prevTasks) => prevTasks.filter((task) => task.ID !== ID))
        Swal.fire('Deleted!', 'Task has been deleted successfully.', 'success')
      } catch (error) {
        console.error('Delete error:', error)
        Swal.fire('Error!', 'Failed to delete task.', 'error')
      }
    }
  }
  function handleComment(ID: number): void {
    throw new Error('Function not implemented.')
  }

  return (
    <div className='card'>
      <div className='card-header border-0 pt-5 d-flex justify-content-end'>
        <div className='card-toolbar'>
          <Button variant='primary' onClick={() => handleAdd()}>
            Add Task
          </Button>
        </div>
      </div>
      <div className='card-body py-3'>
        {tasks.length > 0 ? (
          <div className='table-responsive'>
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
              <thead>
                <tr className='fw-bold text-muted'>
                  <th>Actions</th>
                  <th>Responsible</th>
                  <th>Subject</th>
                  <th>Action</th>
                  <th>Category</th>
                  <th>Status</th>
                  <th>Created At</th>
                  <th>Last Updated</th>
                  <th>Due Date</th>
                </tr>
              </thead>
              <tbody>
                {tasks.map((task) => (
                  <tr key={task.ID}>
                    <td>
                      <div className='d-flex justify-content-end flex-shrink-0'>
                        <button
                          onClick={() => handleView(task.ID)}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                          <span className='fa fa-edit'></span>
                        </button>
                        <button
                          onClick={() => handleDelete(task.ID)}
                          className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'
                        >
                          <span className='fa fa-trash'></span>
                        </button>
                        <button
                          onClick={() => handleComment(task.ID)}
                          className='btn btn-icon btn-bg-light btn-active-color-info btn-sm me-1'
                        >
                          <span className='fa fa-comment'></span>
                        </button>
                      </div>
                    </td>
                    <td>{task.responsible}</td>
                    <td>
                      {(() => {
                        let subjectLabel = ''
                        switch (task.subject) {
                          case 'meter_upgrade':
                            subjectLabel = 'Meter Upgrade'
                            break
                          case 'behavourial_changes':
                            subjectLabel = 'Behavourial Changes'
                            break
                          case 'software':
                            subjectLabel = 'Software'
                            break
                          case 'procurement':
                            subjectLabel = 'Procurement'
                            break
                          case 'lighting_upgrade':
                            subjectLabel = 'Lighting Upgrade'
                            break
                          case 'lighting_controls':
                            subjectLabel = 'Lighting Controls'
                            break
                        }
                        return subjectLabel
                      })()}
                    </td>
                    <td>{task.action}</td>
                    <td>
                      {(() => {
                        let categoryLabel = ''
                        switch (task.category) {
                          case 'esos':
                            categoryLabel = 'ESOS'
                            break
                          case 'action_plan':
                            categoryLabel = 'Action Plan'
                            break
                          case 'procurement':
                            categoryLabel = 'Procurement'
                            break
                          case 'secr':
                            categoryLabel = 'SECR'
                            break
                        }
                        return categoryLabel
                      })()}
                    </td>

                    <td>
                      {(() => {
                        let badgeClass = 'badge-success'
                        let statusLabel = ''
                        switch (task.status) {
                          case 'in_progress':
                            badgeClass = 'badge-primary'
                            statusLabel = 'In Progress'
                            break
                          case 'pending':
                            badgeClass = 'badge-warning'
                            statusLabel = 'Pending'
                            break
                          case 'completed':
                            badgeClass = 'badge-success'
                            statusLabel = 'Completed'
                            break
                          case 'closed':
                            badgeClass = 'badge-dark'
                            statusLabel = 'Closed'
                            break
                          case 'outstanding':
                            badgeClass = 'badge-danger'
                            statusLabel = 'Outstanding'
                            break
                        }
                        return <div className={'badge ' + badgeClass}>{statusLabel}</div>
                      })()}
                    </td>
                    <td>{task.created_at}</td>
                    <td>{task.last_updated}</td>
                    <td>{task.due_date}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className='text-center py-10'>
            <h3 className='text-muted'>No tasks available.</h3>
          </div>
        )}
      </div>

      {itemIdForUpdate !== '' && (
        <TaskEditModal show={true} itemIdForUpdate={itemIdForUpdate} onHide={handleRefreshNotes} />
      )}
    </div>
  )
}

export default CompanyTasks
