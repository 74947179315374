import React from 'react'
import { Navigate, Route, Routes, Outlet } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { AccountHeader } from '../accounts/AccountHeader'
import { Overview } from '../accounts/components/Overview'
import { Settings } from '../accounts/components/settings/Settings'
import { CompanyDetails } from './components/CompanyDetails'
import { CompanyHeader } from './components/CompanyHeader'
import CompanyNotes from './components/CompanyNotes'
import CompanyTasks from './components/CompanyTasks'

const accountBreadCrumbs: Array<PageLink> = [
  {
    title: 'Company Details',
    path: '/company_overview',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const CompanyOverviewPage: React.FC = () => {
  return (
    <Routes>
      <Route
        element={
          <>
            <CompanyHeader />
          </>
        }
      >
        <Route
          path='details/:id'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>Overview</PageTitle>
              <CompanyHeader />
            </>
          }
        />
        <Route
          path='notes/:id'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>Notes</PageTitle>
              <CompanyHeader />
            </>
          }
        />
        <Route
          path='tasks/:id'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>Tasks</PageTitle>
              <CompanyHeader />
            </>
          }
        />
        <Route index element={<Navigate to='/company_overview/details' />} />
      </Route>
    </Routes>
  )
}

export default CompanyOverviewPage
function useParams(): { id: any } {
  throw new Error('Function not implemented.')
}
