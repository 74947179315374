import React, { FC, SyntheticEvent, useState } from 'react'
import CompanyTable from './table/CompanyTable'
import axios from 'axios'
import Swal from 'sweetalert2'
import { CompanyEditModal } from '../CompanyEditModal'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import CompanyNotesModal from './CompanyNotesModal'

const CompanyList: FC = () => {
  const [itemIdForUpdate, setItemIdForUpdate] = useState('')
  const [itemIdForView, setItemIdForView] = useState('')
  const [refreshFunction, setRefreshFunction] = useState<() => void>(() => {})
  const navigate = useNavigate()
  const intl = useIntl()

  return (
    <>
      <CompanyTable
        title={intl.formatMessage({ id: 'MENU.COMPANYLIST' })}
        dataUrl='/company/list'
        editCallback={(id: any, refresh: Function) => {
          setRefreshFunction(() => refresh)
          setItemIdForUpdate(id)
        }}
        deleteCallback={(id: any, refresh: Function) => {
          axios.get('/company/save/' + id).then((response) => {
            let csrfData = response.data.data
            Swal.fire({
              title: 'Warning',
              html: 'Are you sure you want to delete this company?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Delete',
              cancelButtonText: 'Cancel',
              customClass: {
                confirmButton: 'btn btn-danger',
                cancelButton: 'btn btn-primary',
              },
            }).then((result) => {
              if (result.isConfirmed) {
                axios
                  .delete('/company/save/' + id, {
                    data: csrfData,
                  })
                  .then(() => {
                    refresh()
                  })
              }
            })
          })
        }}
        //@ts-ignore
        viewCallback={(id: any, refresh: Function) => {
          setRefreshFunction(() => refresh)
          setItemIdForView(id)
        }}
        //@ts-ignore
        overviewCallback={(id: any, refresh: Function) => {
          navigate('/company_overview/details/' + id)
        }}
        actionItems={[
          {
            text: intl.formatMessage({ id: 'COMPANY.ADDNEWCOMPANY' }),
            class: 'btn btn-sm btn-primary',
            icon: 'far fa-building',
            onClick: function (event: SyntheticEvent, refresh: Function) {
              setItemIdForUpdate('add')
              setRefreshFunction(() => refresh)
            },
          },
        ]}
      />
      {itemIdForUpdate !== '' && (
        <CompanyEditModal
          show={itemIdForUpdate !== ''}
          itemIdForUpdate={itemIdForUpdate}
          onHide={() => setItemIdForUpdate('')}
          refreshFunction={(itemId: any) => {
            if (itemId !== undefined) {
              setItemIdForUpdate(itemId)
            }
            refreshFunction()
          }}
        />
      )}
      {itemIdForView && (
        <CompanyNotesModal
          show={itemIdForView !== ''}
          itemId={itemIdForView}
          onHide={() => setItemIdForView('')}
        />
      )}
    </>
  )
}

export { CompanyList }
