import axios from 'axios'
import React, { FC, useEffect, useState } from 'react'
import { Modal, Button, Badge } from 'react-bootstrap'
import { Navigate, useNavigate } from 'react-router-dom'
import { CommentEditModal } from './CommentEditModal'

interface CompanyNotesModalProps {
  show: boolean
  itemId: string
  onHide: () => void
}

interface Comment {
  ID: number
  task: string
  user: string
  body: string
  created_at: string
  last_updated: string
}

const TaskCommentModal: FC<CompanyNotesModalProps> = ({ show, itemId, onHide }) => {
  const navigate = useNavigate()
  const [comments, setComments] = useState<Comment[]>([])
  const [itemIdForUpdate, setItemIdForUpdate] = useState('')

  const getComments = () => {
    axios.get('/task_comments/getComments/' + itemId).then((response) => {
      if (response.data.data) {
        setComments(response.data.data)
      }
    })
  }

  const handleView = (id: number) => {
    setItemIdForUpdate(id.toString())
  }

  const handleAdd = () => {
    setItemIdForUpdate('add')
  }

  useEffect(() => {
    if (show) {
      getComments()
    }
  }, [show])

  // Refresh notes after editing
  const handleRefreshComments = () => {
    getComments()
    setItemIdForUpdate('') // Reset the item ID for update
  }

  return (
    <>
      <Modal show={show} onHide={onHide} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Comments</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: '70vh', overflowY: 'auto' }}>
          {comments.length > 0 ? (
            <table className='table table-striped table-hover'>
              <thead>
                <tr>
                  <th>Actions</th>
                  <th>User</th>
                  <th>Comment</th>
                  <th>Created At</th>
                  <th>Last Updated</th>
                </tr>
              </thead>
              <tbody>
                {comments.map((comment) => (
                  <tr key={comment.ID}>
                    <td>
                      <Button
                        variant='primary'
                        size='sm'
                        style={{ marginLeft: '5px', padding: '2px 5px', fontSize: '12px' }}
                        onClick={() => handleView(comment.ID)}
                      >
                        View
                      </Button>
                    </td>
                    <td>{comment.user}</td>
                    <td>
                      {comment.body.length > 50
                        ? comment.body.substring(0, 50) + '...'
                        : comment.body}
                    </td>

                    <td>{comment.created_at}</td>
                    <td>{comment.last_updated}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No comments available.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={() => handleAdd()}>
            Add Comment
          </Button>
          <Button variant='info' onClick={() => navigate('/task_comments')}>
            All Comments
          </Button>
          <Button variant='secondary' onClick={onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {itemIdForUpdate !== '' ? (
        <CommentEditModal
          show={true}
          itemIdForUpdate={itemIdForUpdate}
          onHide={() => {
            handleRefreshComments()
          }}
        />
      ) : null}
    </>
  )
}

export default TaskCommentModal
